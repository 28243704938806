<template>
  <div>
    <form-header></form-header>
    <table class="table">
      <tr>
        <td rowspan="3" class="width_0">
          <div v-if="bilifilter(item) < 0" class="tj tjbg5">难</div>
          <div v-else-if="bilifilter(item) < 40" class="tj tjbg1">冲</div>
          <div v-else-if="bilifilter(item) < 65" class="tj tjbg2">稳</div>
          <div v-else-if="bilifilter(item) < 89" class="tj tjbg3">保</div>
          <div v-else-if="bilifilter(item) > 88" class="tj tjbg4">垫</div>
          <div v-if="(oldScoreArt[2] * Number(item.z_scale_2) + oldScore[2] * Number(item.w_scale_2)) /
            100 - item.min_score_2 < 0" style="font-size: 14px;  line-height: 20px; "> 谨慎填报</div>

          <div v-else-if="(oldScoreArt[1] * Number(item.z_scale_1) + oldScore[1] * Number(item.w_scale_1)) /
            100 - item.min_score_1 < 0" style="font-size: 14px;  line-height: 20px; "> 谨慎填报</div>

          <!-- <div> {{ bilifilter(item) }}% </div> -->
        </td>
        <td rowspan="3" class="width_1">
          <div class="collegeData">
            <div>
              {{ item.name }} [{{ item.school_id }}]
              <span class="remarks" v-if="item.batch == 5">原A</span>
              <span class="remarks" v-if="item.batch == 6">原B</span>
              <span class="remarks" v-if="item.batch == 4">原提前批</span>

              <span class="sp1">办学性质：{{ item.nature }} </span>
              <br>
              <!-- <span class="sp2">属地：{{ item.city_name.replace('省', '') }}</span> -->
              <span class="sp1" v-if="item.address">地址：{{ item.address.replace('市', '') }}</span>
            </div>
            <div>
              {{ item.specialty_name }} [{{ item.specialty_id }}] <span class="sp1">{{ item.lqgz }}</span>
            </div>
            <div>
              ({{ item.tuition == null ? '' : item.tuition + '元/年' }} <span class="sp2">{{ item.remarks }}</span> )
              <span class="sp2"> {{ item.year }}招生计划人数{{ item.plan_num }}人</span>
            </div>
          </div>
        </td>
        <td class="width_2">{{ item.year_1 }}</td>
        <td class="width_2">{{ item.plan_num_1 }}</td>
        <td class="width_2">{{ item.matriculate_1 }}</td>
        <td class="width_2">{{ item.min_score_1 }}</td>

        <td class="width_2" v-if="item.min_score_1">
          {{ item.min_score_1 - fencha1(item, item.batch) | filterTwo }}
        </td>
        <td class="width_2" v-else>/</td>

        <td class="width_2" v-if="item.min_score_1">{{ (oldScoreArt[1] * Number(item.z_scale_1) + oldScore[1] *
            Number(item.w_scale_1)) /
            100 | filterTwo }}
        </td>
        <td class="width_2" v-else>/</td>

        <td class="width_2" v-if="item.min_score_1">
          {{ (oldScoreArt[1] * Number(item.z_scale_1) + oldScore[1] * Number(item.w_scale_1)) /
            100 - fencha1(item, item.batch) | filterTwo }}
        </td>
        <td class="width_2" v-else>/</td>

        <td v-if="item.min_score_1" class="width_2">
          {{ (oldScoreArt[1] * Number(item.z_scale_1) + oldScore[1] * Number(item.w_scale_1)) /
            100 - item.min_score_1 | filterTwo }}
        </td>
        <td class="width_2" v-else>/</td>
        <td class="width_3" rowspan="3">
          <el-popover placement="bottom" width="200" v-model="visible" trigger="click" popper-class="popover-f">
            <div class="popover-box">
              <p class="box-title">填报为平行志愿</p>
              <span v-for="h in len" :key="h" class="box-item" @click="move(item, h - 1)">{{ h }}</span>
            </div>
            <el-button v-if="record.indexOf(item.select_code) > -1" slot="reference" size="mini" round plain
              class="btn schoolBtn">
              志愿{{ record.indexOf(item.select_code) + 1 }}
            </el-button>
            <el-button v-else slot="reference" size="mini" round plain>填报为</el-button>
          </el-popover>
          <el-button size="mini" round plain type="primary" @click="btnOrtherSpecialyt(item)">查看其他专业</el-button>

        </td>
      </tr>
      <tr>
        <td>{{ item.year_1 - 1 }}</td>
        <td>{{ item.plan_num_2 }}</td>
        <td>{{ item.matriculate_2 }}</td>
        <td>{{ item.min_score_2 }}</td>

        <td v-if="item.min_score_2">{{ item.min_score_2 - fencha2(item, item.batch) | filterTwo }}</td>
        <td v-else></td>

        <td v-if="item.min_score_2">{{ (oldScoreArt[2] * Number(item.z_scale_2) + oldScore[2] * Number(item.w_scale_2))
            /
            100 | filterTwo }}</td>
        <td v-else></td>

        <td v-if="item.min_score_2">
          {{ (oldScoreArt[2] * Number(item.z_scale_2) + oldScore[2] * Number(item.w_scale_2)) /
            100 - fencha2(item, item.batch) | filterTwo }}
        </td>
        <td v-else></td>

        <td v-if="item.min_score_2">
          {{ (oldScoreArt[2] * Number(item.z_scale_2) + oldScore[2] * Number(item.w_scale_2)) /
            100 - item.min_score_2 | filterTwo }}
        </td>
        <td v-else></td>
      </tr>
      <tr>
        <td>{{ item.year_1 - 2 }}</td>
        <td>{{ item.plan_num_3 }}</td>
        <td>{{ item.matriculate_3 }}</td>
        <td>{{ item.min_score_3 }}</td>
        <td v-if="item.min_score_3">{{ item.min_score_3 - fencha3(item, item.batch) | filterTwo }}</td>
        <td v-else></td>
        <td v-if="item.min_score_3">{{ (oldScoreArt[3] * Number(item.z_scale_3) + oldScore[3] * Number(item.w_scale_3))
            /
            100 | filterTwo }}</td>
        <td v-else></td>

        <td v-if="item.min_score_3">
          {{ (oldScoreArt[3] * Number(item.z_scale_3) + oldScore[3] * Number(item.w_scale_3)) /
            100 - fencha3(item, item.batch) | filterTwo }}
        </td>
        <td v-else></td>

        <td v-if="item.min_score_3">
          {{ (oldScoreArt[3] * Number(item.z_scale_3) + oldScore[3] * Number(item.w_scale_3)) /
            100 - item.min_score_3 | filterTwo }}
        </td>
        <td v-else></td>
      </tr>
    </table>



    <el-dialog :visible.sync="dialogVisible" destroy-on-close :close-on-click-modal="false" lock-scroll
      :before-close="closeDialog" width="1200px">
      <FormCollege v-for="(value, key) in ortherSpecialyt" :key="key" :item="value" :oldScoreArt="oldScoreArt"
        :oldScore="oldScore" :oldLine="oldLine" :art_batch="art_batch">
      </FormCollege>
    </el-dialog>

  </div>
</template>



<script>
import { mapState, mapMutations } from "vuex";
import FormHeader from './FormHeader.vue';
import FormCollege from './FormCollege.vue';
export default {
  props: ['item', 'oldScoreArt', 'oldScore', 'oldLine', 'art_batch'],
  name: '',
  data() {
    return {
      oldLine_1: [],
      oldLine_2: [],
      oldLine_3: [],
      dialogVisible: false,
      ortherSpecialyt: [],
      len: 45,
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十", '十一', '十二'],
      visible: false,
      record: []
    };
  },
  inject: ['callBack'],
  components: {
    FormHeader, FormCollege,
  },
  computed: {
    ...mapState(["userInfo", 'artFormList', 'changeArtState']),
    fencha1() {
      return function (item, batch) {
        if (this.oldLine_1[0]) {
          switch (Number(batch)) {
            case 4:
            case 5:
              return (item.w_scale_1 * this.oldLine_1[0].curture_score + item.z_scale_1 * this.oldLine_1[0].major_score) / 100;
            case 6:
              return (item.w_scale_1 * this.oldLine_1[1].curture_score + item.z_scale_1 * this.oldLine_1[1].major_score) / 100;
            case 3:
              return (item.w_scale_1 * this.oldLine_1[2].curture_score + item.z_scale_1 * this.oldLine_1[2].major_score) / 100;
          }
        }
      };
    },
    fencha2() {
      return function (item, batch) {
        if (this.oldLine_2[0]) {
          switch (Number(batch)) {
            case 4:
            case 5:
              return (item.w_scale_2 * this.oldLine_2[0].curture_score + item.z_scale_2 * this.oldLine_2[0].major_score) / 100;
            case 6:
              return (item.w_scale_2 * this.oldLine_2[1].curture_score + item.z_scale_2 * this.oldLine_2[1].major_score) / 100;
            case 3:
              return (item.w_scale_2 * this.oldLine_2[2].curture_score + item.z_scale_2 * this.oldLine_2[2].major_score) / 100;
          }
        }
      };
    },
    fencha3() {
      return function (item, batch) {
        if (this.oldLine_3[0]) {
          switch (Number(batch)) {
            case 4:
            case 5:
              return (item.w_scale_3 * this.oldLine_3[0].curture_score + item.z_scale_3 * this.oldLine_3[0].major_score) / 100;
            case 6:
              return (item.w_scale_3 * this.oldLine_3[1].curture_score + item.z_scale_3 * this.oldLine_3[1].major_score) / 100;
            case 3:
              return (item.w_scale_3 * this.oldLine_3[2].curture_score + item.z_scale_3 * this.oldLine_3[2].major_score) / 100;
          }
        }
      };
    },
    bilifilter() {
      return function (value) {
        let item = value.bili

        item = (item * 1000).toFixed(1)
        if (item <= 30)

          return (Number(item) + (item) * 1 / 6).toFixed(0);

        if (item <= 65) {
          return (40 + (item - 30) * 0.57).toFixed(0);
        }

        if (item <= 85) {
          return Number(item).toFixed(0);
        }

        if (item > 85)
          return 95;
      }
    },

  },
  filters: {
    filterTwo(item) {
      return item.toFixed(0)
    },

  },
  watch: {
    changeArtState() {
      this.setRecord()
    },
    oldLine(e) {
      if (e) {
        this.oldLine_1 = e.slice(0, 3)
        this.oldLine_2 = e.slice(3, 6)
        this.oldLine_3 = e.slice(6, 9)
      }
    },
  },
  created() {
    if (this.oldLine) {
      this.oldLine_1 = this.oldLine.slice(0, 3)
      this.oldLine_2 = this.oldLine.slice(3, 6)
      this.oldLine_3 = this.oldLine.slice(6, 9)
      console.log(this.oldLine_1)
    }
    this.setRecord()
  },
  methods: {
    ...mapMutations(['setArtFromList']),

    btnOrtherSpecialyt(item) {
      this.dialogVisible = true
      let data = {
        art_batch: item.art_batch,
        score: this.oldScore[1],
        z_score: this.oldScoreArt[1],
        is_wenli: item.is_wenli,
        school_id: item.school_id
      }
      this.$fecth.post('art_t_school_score/getCollegeSpecialty', data).then((res) => {
        this.ortherSpecialyt = res
      })
    },

    move(e, i) {
      let data = this.artFormList
      let index = this.record.indexOf(e.select_code)
      //判断移动的位置是否被填报过 有的话删除
      if (index > -1) {
        this.record[index] = null
        data[index] = null
      }
      data[i] = e
      this.record[i] = e.select_code
      this.setArtFromList(data)
      this.visible = false
      this.callBack()
    },

    setRecord() {
      this.artFormList.forEach((el, i) => {
        if (el) {
          this.record[i] = el.select_code
        }
      });
      this.$forceUpdate()
    },
    closeDialog() {
      this.setRecord()
      this.dialogVisible = false
    }
  },
};
</script>

<style scoped lang='less'>
.table {
  width: 100%;
  border-collapse: collapse;

  td {
    border: 1px solid #dfdfdf;
    text-align: center;
    line-height: 35px;
    color: rgb(46, 46, 46);
  }

  .tj {
    border-radius: 7px;
    width: 23px;
    height: 23px;
    line-height: 23px;
    margin: auto;
    text-align: center;

  }

  .tjbg5 {
    color: white;
    background: rgb(187, 0, 0);
  }

  .tjbg1 {
    color: white;
    background: red;
  }

  .tjbg2 {
    color: white;
    background: #f47400;
  }

  .tjbg3 {
    color: white;
    background: #1787e0;
  }

  .tjbg4 {
    color: white;
    background: #00cc82;
  }

  .width_0 {
    width: 40px;
  }

  .width_1 {
    // width: 520px;
  }

  .width_2 {
    width: 55px;
  }

  .width_3 {
    width: 140px;
  }

  .sp1 {
    color: gray;
    margin-right: 25px;
    margin-left: 15px;
  }

  .sp2 {
    color: gray;
  }

  .collegeData {
    text-align: left;
    line-height: 30px;
    padding: 15px 20px;
  }

}

.remarks {
  font-size: 12px;
  color: red;
}

.popover-f {
  width: 70px;
  background-color: #3d3d3c;
  color: white;
  min-width: 0;

  .popper__arrow {
    display: none;
  }

  .box-title {
    padding-bottom: 10px;
    border-bottom: 1px solid white;
  }

  .box-item {
    border: 1px solid #dfdfdf;
    display: inline-block;
    padding: 5px 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .box-item:hover {
    background: #f4f7fc;
  }
}
</style>
